import React from 'react'
import { motion } from 'framer-motion'
import { Link } from 'gatsby'
import Box from './Box'
import Flex from './Flex'
import Text from './Text'
import Heading from './Heading'

const Articles = ({ posts }) => {
  return posts.map(({ node: post }) => {
    const { frontmatter, fields, excerpt } = post

    return (
      <Box key={post.id} width={1}>
        <Link to={fields.slug.replace(/\/$/, '')}>
          <Flex
            as={motion.div}
            flexDirection={['column', 'column', 'row']}
            justifyContent='space-between'
            alignItems={['flex-start', 'flex-start']}
            py='3'
            whileHover={{
              opacity: 0.6,
            }}
          >
            <Heading flex='3' as='h3' fontSize='6' fontWeight='1'>
              {frontmatter.title}
            </Heading>

            <Text
              flex='1'
              mt={[2, 3, 0]}
              fontWeight='2'
              color='gray.300'
              textAlign='right'
            >
              {frontmatter.date}
            </Text>
          </Flex>
        </Link>
      </Box>
    )
  })
}

export default Articles
