import React from 'react'
import { format, intervalToDuration, formatDuration } from 'date-fns'
import css from '@styled-system/css'
import { motion } from 'framer-motion'
import {
  Box,
  Container,
  Flex,
  Heading,
  Section,
  Seo,
  Text,
} from '../components'

const jobs = [
  {
    jobTitle: 'Software Engineer',
    jobType: 'Full-time',
    companyName: 'StickerMule',
    companyUrl: 'https://StickerMule.com',
    startDate: new Date('Dec, 05, 2022'),
    endDate: null,
  },
  {
    jobTitle: 'Software Engineer',
    jobType: 'Full-time',
    companyName: 'Codeminer42',
    companyUrl: 'https://codeminer42.com',
    startDate: new Date('May, 2017'),
    endDate: new Date('Dec, 02, 2022'),
  },
  {
    jobTitle: 'Software Engineer',
    jobType: 'Contractor',
    companyName: 'StackCommerce',
    companyUrl: 'https://www.stackcommerce.com',
    startDate: new Date('May, 2022'),
    endDate: new Date('Dec, 2022'),
  },
  {
    jobTitle: 'Software Engineer',
    jobType: 'Contractor',
    companyName: '9Count',
    companyUrl: 'https://9count.co',
    startDate: new Date('Feb, 2022'),
    endDate: new Date('May, 2022'),
  },
  {
    jobTitle: 'Software Engineer',
    jobType: 'Contractor',
    companyName: 'LiftForward',
    companyUrl: 'https://liftforward.com',
    startDate: new Date('October, 2021'),
    endDate: new Date('Feb, 2022'),
  },
  {
    jobTitle: 'Software Engineer',
    jobType: 'Contractor',
    companyName: 'Edlio',
    companyUrl: 'https://edlio.com',
    startDate: new Date('May, 2021'),
    endDate: new Date('September, 2021'),
  },
  {
    jobTitle: 'Software Engineer',
    jobType: 'Contractor',
    companyName: 'GoDaddy',
    companyUrl: 'https://godaddy.com',
    startDate: new Date('January, 2018'),
    endDate: new Date('May, 2021'),
  },
  {
    jobTitle: 'Front-End Engineer',
    jobType: 'Contractor',
    companyName: 'Folha de S.Paulo',
    companyUrl: 'https://folha.uol.com.br',
    startDate: new Date('July, 2017'),
    endDate: new Date('December, 2017'),
  },
  {
    jobTitle: 'Software Engineer',
    jobType: 'Full-time',
    companyName: 'CWI Software',
    companyUrl: 'https://cwi.com.br',
    startDate: new Date('September, 2015'),
    endDate: new Date('May, 2017'),
  },
]

const AboutPage = (props) => {
  return (
    <>
      <Seo
        title='About'
        description='About Me'
        pathname={props.location.pathname}
      />

      <Section>
        <Container
          as={Flex}
          flexDirection='column'
          justifyContent='center'
          alignItems='flex-start'
        >
          <Heading as='h2' fontFamily='heading' fontSize='8' mb='5'>
            About Us
          </Heading>

          <Box color='gray.100'>
            <Text lineHeight='3.2rem'>
              Welcome to Zen Workspaces, your go-to destination for curated
              essentials that elevate your work and play environments. At Zen
              Workspaces, we believe in the power of a clean and minimalist
              aesthetic to enhance your daily life, starting with your
              workspace.
            </Text>

            <Text lineHeight='3.2rem' mt='4'>
              Navigate through our thoughtfully categorized sections, where
              you'll find Amazon links to products that align with our clean and
              minimalist ideology. Whether you're a professional seeking a sleek
              desk setup, a tech enthusiast exploring the latest gadgets, or
              someone in search of ergonomic accessories, Zen Workspaces has you
              covered.
            </Text>

            <Text lineHeight='3.2rem' mt='4'>
              We are committed to curating a range of high-quality products that
              not only meet your functional needs but also complement your
              style. From sleek and modern computer peripherals to space-saving
              desk organizers, each item in our collection has been chosen with
              an emphasis on simplicity, functionality, and aesthetics.
            </Text>

            <Text lineHeight='3.2rem' mt='4'>
              At Zen Workspaces, we understand that your environment plays a
              crucial role in your overall well-being, and we aim to make the
              process of creating your ideal workspace a breeze. Join us on the
              journey to a more zen and organized life – explore our store,
              discover new possibilities, and transform your workspace into a
              haven of productivity and tranquility. Welcome to Zen Workspaces,
              where simplicity meets functionality for a harmonious work and
              play experience.
            </Text>
          </Box>
        </Container>
      </Section>
    </>
  )
}

export default AboutPage
